import Page from "../../classes/Page";
import ImageLazyLoader from "../../components/ImageLazyLoader";
import { mapEach } from "../../utils/dom";

export default class extends Page {
  constructor() {
    super({
      classes: {
        active: "work--active",
      },
      element: ".work",
      elements: {
        wrapper: ".work__wrapper",
        lazyLoad: "[data-lazy-load]",
      },
    });
  }

  show() {
    this.element.classList.add(this.classes.active);

    this.createLazyLoad();

    return super.show();
  }

  hide() {
    this.element.classList.remove(this.classes.active);

    return super.hide();
  }

  createLazyLoad() {
    mapEach(this.elements.lazyLoad, (element) => {
      new ImageLazyLoader({
        element,
      });
    });
  }

  onResize() {
    super.onResize();
  }

  update() {
    super.update();
  }
}
