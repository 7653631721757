import gsap from "gsap";

export default class Eye {
  constructor({ element, index }) {
    this.element = element;
    this.index = index;
    this.maxTrans = 60;
  }

  // Create a max value for the translation in the x and y directions

  // Create a max distance for the mouse position to the center of the element (the viewport dimensions wouldn't be a bad choice).

  onResize() {
    this.maxXDist = innerWidth / 2;
    this.maxYDist = innerHeight / 2;

    const eyeArea = this.element.getBoundingClientRect();
    const R = eyeArea.width / 2;
    this.centerX = eyeArea.left + R;
    this.centerY = eyeArea.top + R;
  }

  updateTrans(e) {
    // Calculate the distance from the mouse position to the center.
    const x = e.clientX - this.centerX;
    const y = e.clientY - this.centerY;
    // const dist = Math.sqrt(Math.pow(x, 2) + Math.pow(x, 2)); // optionally use the total distance as a factor or restriction

    // Put that number over the max distance from 2)
    const xPercent = x / this.maxXDist;
    const yPercent = y / this.maxYDist;

    // Multiply that product by the max value from 1 and apply it to your element.
    const scaledXPercent = xPercent * this.maxTrans;
    const scaledYPercent = yPercent * this.maxTrans;

    gsap.to(this.element, {
      xPercent: scaledXPercent,
      yPercent: scaledYPercent,
      delay: this.index / 20,
      duration: 0.2,
      overwrite: "auto",
    });
  }
}
