import { findAncestor, getMousePos } from "../utils/dom";
import { lerp } from "../utils/math";
// import { lerp, getMousePos } from "../utils";

// Track the mouse position
let mouse = { x: 0, y: 0 };
if (typeof window !== "undefined") {
  window.addEventListener("mousemove", (ev) => (mouse = getMousePos(ev)));
}

export class Cursor {
  constructor(el) {
    this.DOM = { el: el };
    this.DOM.el.style.opacity = 0;

    this.bounds = this.DOM.el.getBoundingClientRect();

    this.renderedStyles = {
      tx: { previous: 0, current: 0, amt: 1 },
      ty: { previous: 0, current: 0, amt: 1 },
      scale: { previous: 1, current: 1, amt: 0.15 },
      opacity: { previous: 1, current: 1, amt: 0.1 },
    };

    this.leave();

    this.dx = -40;
    this.dy = -32;

    this.onMouseMoveEv = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current =
        mouse.x - this.bounds.width / 2;
      this.renderedStyles.ty.previous = this.renderedStyles.ty.previous =
        mouse.y - this.bounds.height / 2;

      window.removeEventListener("mousemove", this.onMouseMoveEv);
    };
    window.addEventListener("mousemove", this.onMouseMoveEv);
  }
  enter() {
    this.renderedStyles["scale"].current = 1;
    this.renderedStyles["opacity"].current = 1;
  }
  leave() {
    this.renderedStyles["scale"].current = 0;
    this.renderedStyles["opacity"].current = 0;
  }

  checkTarget(e) {
    const el = e.target;
    const project = findAncestor(el, "c-project");

    if (project.classList.contains("active")) {
      this.DOM.el.classList.add("active");
    } else {
      this.DOM.el.classList.remove("active");
    }
  }

  checkEnter() {
    [...document.querySelectorAll("[data-click-item]")].forEach((link) => {
      link.addEventListener("mouseenter", (e) => {
        this.enter();
        this.checkTarget(e);

        if (e.target.classList.contains("c-project__link")) {
          this.leave();
        }
      });

      link.addEventListener("mousemove", (e) => {
        if (e.target.classList.contains("c-project__link")) {
          this.leave();
        } else {
          this.enter();
        }
      });

      link.addEventListener("click", (e) => {
        setTimeout(() => {
          this.checkTarget(e);
        }, 0);
      });

      link.addEventListener("mouseleave", () => {
        this.leave();
      });
    });
  }

  updateBounds() {
    this.bounds = this.DOM.el.getBoundingClientRect();
  }

  update() {
    this.renderedStyles["tx"].current =
      mouse.x - this.bounds.width / 2 - this.dx;
    this.renderedStyles["ty"].current =
      mouse.y - this.bounds.height / 2 - this.dy;

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(
        this.renderedStyles[key].previous,
        this.renderedStyles[key].current,
        this.renderedStyles[key].amt
      );
    }

    this.DOM.el.style.transform = `translateX(${this.renderedStyles["tx"].previous}px) translateY(${this.renderedStyles["ty"].previous}px) scale(${this.renderedStyles["scale"].previous})`;
    this.DOM.el.style.opacity = this.renderedStyles["opacity"].previous;
  }
}
