import axios from "axios";
import Component from "../classes/Component";
import Airtable from "airtable";
import { getOffset } from "../utils/dom";

export default class Spotify extends Component {
  constructor() {
    super({
      element: ".pages",
      elements: {
        title: "[data-title]",
        artist: "[data-artist]",
        nowPlaying: "[data-now-playing]",
      },
    });

    Airtable.configure({
      endpointUrl: "https://api.airtable.com",
      apiKey: process.env.AIRTABLE_API_KEY,
    });

    this.base = Airtable.base(process.env.AIRTABLE_BASE_ID);

    this.init();
  }

  async getNewToken() {
    // application/x-www-form-urlencoded parameters
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", process.env.SPOTIFY_REFRESH_TOKEN);

    const encodedSecret = Buffer.from(
      process.env.SPOTIFY_CLIENT_ID + ":" + process.env.SPOTIFY_CLIENT_SECRET
    ).toString("base64");

    const headers = {
      Authorization: `Basic ${encodedSecret}`,
    };

    // fetch token with POST request
    const res = await axios.post(
      "https://accounts.spotify.com/api/token",
      params,
      { headers }
    );

    return res;
  }

  async updateToken() {
    const res = await this.getNewToken();
    const resData = res.data;

    const created = Date.now();

    const token = {
      token: resData.access_token,
      expiry: (resData.expires_in - 300) * 1000,
      created,
    };

    this.token = token;

    await this.base("token").update([
      {
        id: "rec9tubHJSwNYOUnZ",
        fields: {
          ...token,
        },
      },
    ]);
  }

  async init() {
    try {
      const tokenValid = (token = {}) => {
        const now = Date.now();
        const expiry = Number(token.created) + Number(token.expiry);

        return now < expiry;
      };

      const res = await this.base("token").select().firstPage();

      this.token = res[0].fields;

      if (this.token && !tokenValid(this.token)) {
        console.log("expired");
        await this.updateToken();
      }

      if (!this.token) {
        await this.updateToken();
      }

      const playing = await this.getNowPlaying();

      this.setNowPlaying(playing);
    } catch (error) {
      if (error.response) {
        if (error.response.data.error.message === "The access token expired") {
          await this.updateToken();
          const playing = await this.getNowPlaying();
          this.setNowPlaying(playing);
        }
      }
    }
  }

  async getNowPlaying() {
    let songName = "";
    let isPlaying = false;
    let artistName = "";
    let url = "";

    const headers = {
      Authorization: `Bearer ${this.token.token}`,
      "Content-Type": "application/json",
      Accept: "application/json",
    };

    const res = await axios.get(
      "https://api.spotify.com/v1/me/player/currently-playing",
      { headers }
    );

    if (
      res.data.is_playing === false ||
      res.data.currently_playing_type !== "track"
    ) {
      const res = await axios.get(
        "https://api.spotify.com/v1/me/player/recently-played",
        { headers }
      );

      if (res.status > 299) {
        // console.error("Error with recently-played API call.", res.statusText);
      }

      const playHistory = res.data.items;

      const recentTrack = playHistory[0].track;

      songName = recentTrack.name;
      isPlaying = false;
      artistName = recentTrack.artists[0].name;
      url = recentTrack.external_urls.spotify;
    } else {
      const track = res.data.item;

      songName = track.name;
      isPlaying = res.data.is_playing;
      artistName = track.artists[0].name;
      url = track.external_urls.spotify;
    }

    return {
      artistName,
      isPlaying,
      songName,
      url,
    };
  }

  setNowPlaying(playing) {
    const { artistName, songName, url } = playing;
    const { title, artist, nowPlaying } = this.elements;

    title.forEach((element) => {
      element.innerHTML = songName;
      element.href = url;
    });

    artist.forEach((element) => {
      element.innerHTML = artistName;
    });

    this.addMarquee();
  }

  addMarquee() {
    const { nowPlaying } = this.elements;

    nowPlaying.forEach((element) => {
      const marquee = element.querySelector("[data-marquee-spotify]");
      const song = element.querySelectorAll(".c-song");

      const wrapperWidth = getOffset(element).width;
      const marqueeWidth = getOffset(marquee).width;

      if (marqueeWidth > wrapperWidth) {
        if (song.length === 1) {
          // add a second song
          const clone = song[0].cloneNode(true);
          marquee.appendChild(clone);
        }

        setTimeout(() => {
          marquee.classList.add("active");
        }, 1000);
      } else {
        marquee.classList.remove("active");
      }
    });
  }
}
