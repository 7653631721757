import Component from "../classes/Component";
import { replaceAtIndex } from "../utils/text";
import { mapEach } from "../utils/dom";

export default class Year extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        footerYear: "[data-footer-year]",
        timeline: "[data-timeline]",
      },
    });

    this.year = `${new Date().getFullYear()}`;

    this.updateCopyrightYear();
    this.updateTimeline();
  }

  updateCopyrightYear() {
    mapEach(this.elements.footerYear, (element) => {
      element.innerHTML = replaceAtIndex(this.year, 1, "k");
    });
  }

  updateTimeline() {
    mapEach(this.elements.timeline, (element) => {
      const timelneText = `2021—${this.year.slice(2)}`;
      element.innerHTML = timelneText;
    });
  }
}
