import Animation from "../classes/Animation";
import { mapElements } from "../utils/dom";
import { preloadImage } from "../utils/image";
import { optimizeCloudinaryUrl, getVideoPoster } from "../utils/cloudinary";

export default class extends Animation {
  constructor({ element }) {
    super({
      element,
    });

    this.elements = mapElements(element, {
      image: "img",
      video: "video",
      loader: ".loader",
    });

    this.onResize();

    if ("IntersectionObserver" in window) {
      this.animateOut();
    }
  }

  async animateIn() {
    if (this.hasAnimatedIn) return;

    const { image, video } = this.elements;

    if (image) {
      const dataSrc = image.getAttribute("pre-src");

      if (dataSrc) {
        const src = optimizeCloudinaryUrl(dataSrc);
        await preloadImage(src);
        image.src = src;
        image.removeAttribute("pre-src");

        setTimeout(() => {
          this.element.classList.add("loaded");
        }, 1000);
      }
    }

    if (video) {
      const dataSrc = video.getAttribute("pre-src");

      if (dataSrc) {
        const src = optimizeCloudinaryUrl(dataSrc);
        const poster = getVideoPoster(dataSrc);

        video.setAttribute("poster", poster);
        video.setAttribute("src", src);
        video.removeAttribute("pre-src");
      }
    }

    this.hasAnimatedIn = true;
  }

  animateOut() {
    if (this.hasAnimatedIn) return;
  }

  onResize() {}
}
