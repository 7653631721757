import * as THREE from "three";
import fragmentShader from "./shaders/fragment.glsl";
import vertexShader from "./shaders/vertex.glsl";

export default class Smoke {
  constructor() {
    this.canvas = document.createElement("canvas");
    document.body.appendChild(this.canvas);
    this.canvas.classList.add("smoke");
    // this.canvas.style.opacity = 0;

    this.video = document.createElement("video");
    this.video.style.opacity = 0;
    document.body.appendChild(this.video);
    this.video.classList.add("smoke-video");
    this.video.classList.add("visually-hidden");
    this.video.src = "textures/mask-smoke-6.mp4";
    this.video.setAttribute("playsinline", "");
    this.video.setAttribute("x5-playsinline", "");
    this.video.setAttribute("muted", "");

    // this.video.setAttribute("autoplay", "");

    this.videoTexture = new THREE.VideoTexture(this.video);

    this.scene = new THREE.Scene();

    this.createCamera();
    this.createRenderer();
    this.createGeometry();
    this.createMesh();

    this.onResize();
  }

  get windowSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  play() {
    this.video.play();
  }

  stop() {
    this.video.pause();
    this.video.currentTime = 0;
  }

  createCamera() {
    this.camera = new THREE.PerspectiveCamera(
      45,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    this.scene.add(this.camera);

    this.camera.position.x = 0;
    this.camera.position.y = 0;
    this.camera.position.z = 100;
    this.camera.lookAt(this.scene.position);
  }

  onResize() {
    const sizes = this.windowSize;

    // Update camera
    this.camera.aspect = sizes.width / sizes.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(sizes.width, sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.planeFitPerspectiveCamera();
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    });

    this.renderer.physicallyCorrectLights = true;
    this.renderer.outputEncoding = THREE.sRGBEncoding;
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping;
    this.renderer.toneMappingExposure = 1.3;
  }

  planeFitPerspectiveCamera() {
    const cameraZ = this.camera.position.z;
    const distance = cameraZ - this.mesh.position.z;
    const vFov = (this.camera.fov * Math.PI) / 180;
    const scaleY = 2 * Math.tan(vFov / 2) * distance;
    const scaleX = scaleY * this.camera.aspect;

    this.mesh.scale.set(scaleX, scaleY, 1);
  }

  createGeometry() {
    this.geometry = new THREE.PlaneGeometry(1, 1);
  }

  createMesh() {
    this.uniforms = {
      uTexture: { value: this.videoTexture },
    };

    this.material = new THREE.ShaderMaterial({
      fragmentShader,
      vertexShader,
      uniforms: this.uniforms,
      transparent: true,
    });

    this.mesh = new THREE.Mesh(this.geometry, this.material);

    this.scene.add(this.mesh);
  }

  update() {
    this.renderer.render(this.scene, this.camera);
  }
}
