export default class SmokeAudio {
  constructor() {
    this.audio = new Audio("audio/smoke-audio.wav");
  }

  play() {
    this.audio.play();
  }

  stop() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }
}
