import Component from "../classes/Component";
import { mapEach } from "../utils/dom";
import { getRandomItem } from "../utils/math";

export default class NotFound extends Component {
  constructor() {
    super({
      element: ".notfound",
      elements: {
        headers: ".notfound__title",
      },
    });

    this.headersLength = this.elements.headers.length;

    this.numbersArray = Array.from(Array(this.headersLength).keys());

    this.duplicateNumbersArray = this.numbersArray.slice();

    setInterval(() => {
      this.showRandomHeader();
    }, 125);
  }

  generateRandomNumber() {
    const number =
      this.duplicateNumbersArray[
        getRandomItem(this.duplicateNumbersArray.length)
      ];

    this.duplicateNumbersArray = this.duplicateNumbersArray.filter(
      (item) => item !== number
    );

    if (this.duplicateNumbersArray.length === 0) {
      this.duplicateNumbersArray = this.numbersArray.slice();
    }

    return number;
  }

  showRandomHeader() {
    const number = this.generateRandomNumber();
    const { headers } = this.elements;

    mapEach(headers, (element, index) => {
      if (index === number) {
        element.classList.add("active");
      } else {
        element.classList.remove("active");
      }
    });
  }
}
