import Component from "../classes/Component";
import { getOffset, mapEach, mapElements } from "../utils/dom";

export default class Project extends Component {
  constructor({ element }) {
    super({
      element,
      elements: {
        projects: "[data-project]",
      },
    });

    this.index = 0;

    this.init();
  }

  init() {
    const { projects } = this.elements;

    if (!projects) return;

    mapEach(projects, (project) => {
      const elements = mapElements(project, {
        clickItem: "[data-click-item]",
      });

      const index = project.dataset.index;

      const { clickItem } = elements;

      mapEach(clickItem, (item) => {
        item.addEventListener("click", (event) => {
          if (event.target.classList.contains("c-project__link")) return;
          this.index = index;
          this.checkActive();

          this.emit("layout-change", project, item);
        });
      });
    });
  }

  checkActive() {
    const { projects } = this.elements;

    mapEach(projects, (project) => {
      if (project.classList.contains("active")) {
        this.collapse(project);
        return;
      }

      if (project.dataset.index === this.index) {
        this.expand(project);
      } else {
        this.collapse(project);
      }
    });
  }

  expand(project) {
    project.classList.add("active");
  }

  collapse(project) {
    project.classList.remove("active");
  }

  setProjectTop(projects) {
    mapEach(projects, (project) => {
      const projectTop = getOffset(project).top;
      project.setAttribute("data-top", projectTop);

      const elements = mapElements(project, {
        clickItem: "[data-click-item]",
      });

      const { clickItem } = elements;

      mapEach(clickItem, (item) => {
        item.setAttribute("data-top", projectTop);
      });
    });
  }

  onResize() {
    const { projects } = this.elements;

    if (!projects) return;

    this.setProjectTop(projects);
  }
}
