import * as THREE from "three";
import * as dat from "dat.gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import gsap from "gsap";
import Smoke from "./Smoke";

export default class Canvas {
  constructor({ url }) {
    this.canvas = document.createElement("canvas");
    this.canvas.classList.add("head");
    document.body.appendChild(this.canvas);
    this.url = url;

    this.enableParallax = false;

    this.cursor = {
      x: 0,
      y: 0,
    };

    this.addDebug();
    this.createRenderer();
    this.createScene();
    this.createCamera();
    this.createControls();
    this.loadAssets();
    this.smoke = new Smoke();
  }

  get windowSize() {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  }

  addDebug() {
    this.debugObject = {};

    this.gui = new dat.GUI();

    // disable gui
    this.gui.domElement.style.display = "none";
  }

  onResize() {
    const sizes = this.windowSize;

    // Update camera
    this.camera.aspect = sizes.width / sizes.height;
    this.camera.updateProjectionMatrix();

    this.renderer.setSize(sizes.width, sizes.height);
    this.renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    this.smoke && this.smoke.onResize();

    // if window width is less than 768px, remove canvas from DOM
    if (sizes.width < 768) {
      this.canvas.remove();
    } else {
      document.body.appendChild(this.canvas);
    }
  }

  onPreloaded() {
    this.stop();
    this.play();

    this.onChangeEnd(this.url, 1.5);
  }

  play() {
    this.smoke.play();
  }

  stop() {
    this.smoke.stop();
  }

  onChangeEnd(url, delay = null) {
    if (url === "/") {
      this.showHead();
      this.createHome(delay);
    } else {
      this.hideHead();
    }
  }

  createRenderer() {
    this.renderer = new THREE.WebGLRenderer({
      canvas: this.canvas,
      antialias: true,
      alpha: true,
    });

    this.renderer.outputEncoding = THREE.sRGBEncoding;
    // this.renderer.toneMapping = THREE.ReinhardToneMapping;
    // this.renderer.toneMappingExposure = 2.5;

    // this.gui.add(this.renderer, "toneMappingExposure").min(0.1).max(10);
    // this.gui.add(this.renderer, "toneMapping").name("Tone Mapping");
  }

  createScene() {
    this.scene = new THREE.Scene();
  }

  createCamera() {
    this.cameraGroupMouse = new THREE.Group();

    this.scene.add(this.cameraGroupMouse);

    const sizes = this.windowSize;

    this.camera = new THREE.PerspectiveCamera(
      75,
      sizes.width / sizes.height,
      0.1,
      2000
    );

    this.camera.position.set(-3.6, 0.29, -1.72);

    // this.camera.rotation.x = -2.97;
    // this.camera.rotation.y = -2;
    // this.camera.rotation.z = -2.98;

    this.scene.add(this.camera);

    this.cameraGroupMouse.add(this.camera);

    // this.gui
    //   .add(this.cameraGroupMouse.rotation, "x", -Math.PI, Math.PI)
    //   .step(0.1);
    // this.gui
    //   .add(this.cameraGroupMouse.rotation, "y", -Math.PI, Math.PI)
    //   .step(0.1);
    // this.gui
    //   .add(this.cameraGroupMouse.rotation, "z", -Math.PI, Math.PI)
    //   .step(0.1);
  }

  createControls() {
    this.controls = new OrbitControls(this.camera, this.canvas);
    this.controls.enableDamping = true;
    this.controls.enabled = false;

    this.controls.addEventListener("end", () => {
      console.log(this.camera.position, this.camera.rotation);
    });
  }

  showHead() {
    // this.head.visible = true;
    this.scene.add(this.head);
  }

  hideHead() {
    // this.head.visible = false;
    this.scene.remove(this.head);
  }

  createHome(delay) {
    this.cursor.x = 0;
    this.cursor.y = 0;

    delay = delay || 0;

    gsap.set(this.cameraGroupMouse.rotation, {
      y: 0,
      z: 0,
    });

    gsap.from(this.head.scale, {
      duration: 1,
      delay: 0.2 + delay,
      x: 1.4,
      y: 1.4,
      z: 1.4,
    });

    gsap.from(this.head.rotation, {
      duration: 1,
      delay: 0.2 + delay,
      x: 0.3,
      y: -0.1,
      z: -0.6,
      onComplete: () => {
        this.enableParallax = true;
      },
    });
  }

  loadAssets() {
    const gltfLoader = new GLTFLoader();
    const cubeTextureLoader = new THREE.CubeTextureLoader();

    const updateAllMaterials = () => {
      this.scene.traverse((child) => {
        if (
          child instanceof THREE.Mesh &&
          child.material instanceof THREE.MeshStandardMaterial
        ) {
          // child.material.map = environmentMapModel;

          // child.material.roughnessMap = environmentMapModel;

          child.rotation.y = -2;

          child.material.envMap = environmentMap;

          child.material.color = new THREE.Color("#202020");

          // add material color top gui
          // this.gui
          //   .addColor(child.material, "color")
          //   .name("Color")
          //   .onFinishChange(() => {
          //     child.material.color = new THREE.Color(child.material.color);
          //   });
          child.material.envMapIntensity = 1;

          this.gui.add(child.material, "envMapIntensity", 0.1, 5).step(0.1);

          child.material.needsUpdate = true;
          // child.castShadow = true;
          // child.receiveShadow = true;
        }
      });
    };

    const environmentMap = cubeTextureLoader.load([
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653628/Group%20256/px_x7self.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653631/Group%20256/nx_siqe6l.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653628/Group%20256/py_q3ddtu.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653628/Group%20256/ny_dmscly.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653628/Group%20256/pz_j0dl6o.jpg",
      "https://res.cloudinary.com/dmwfd0zhh/image/upload/q_auto,f_auto/v1655653628/Group%20256/nz_bpkwta.jpg",
    ]);

    environmentMap.encoding = THREE.sRGBEncoding;
    this.debugObject.envMapIntensity = 1;

    gltfLoader.load("head.glb", (gltf) => {
      gltf.scene.scale.set(1.25, 1.25, 1.25);
      gltf.scene.position.y = -0.25;

      this.head = gltf.scene;

      // this.gui.add(this.head.rotation, "x", -Math.PI, Math.PI).step(0.1);
      // this.gui.add(this.head.rotation, "y", -Math.PI, Math.PI).step(0.1);
      // this.gui.add(this.head.rotation, "z", -Math.PI, Math.PI).step(0.1);

      // this.gui.add(this.head.position, "x", -2, 2).name("positionX").step(0.1);
      // this.gui.add(this.head.position, "y", -2, 2).name("positionY").step(0.1);
      // this.gui.add(this.head.position, "z", -2, 2).name("positionZ").step(0.1);

      this.scene.add(this.head);

      updateAllMaterials();
    });
  }

  // events

  onTouchMove(event) {
    const sizes = this.windowSize;
    const x = event.touches ? event.touches[0].clientX : event.clientX;
    const y = event.touches ? event.touches[0].clientY : event.clientY;

    this.cursor.x = x / sizes.width - 0.5;
    this.cursor.y = y / sizes.height - 0.5;
  }

  update() {
    this.renderer.render(this.scene, this.camera);

    this.controls.update();
    this.smoke && this.smoke.update();

    if (this.enableParallax) {
      const parallaxX = -this.cursor.x;
      const parallaxY = -this.cursor.y;

      this.cameraGroupMouse.rotation.y +=
        (parallaxX - this.cameraGroupMouse.rotation.y) * 0.1;

      this.cameraGroupMouse.rotation.z +=
        (parallaxY - this.cameraGroupMouse.rotation.z) * 0.1;
    }
  }
}
