import Component from "../classes/Component";
import { mapEach } from "../utils/dom";
export default class HoverAudio extends Component {
  constructor() {
    super({
      element: "body",
      elements: {
        hoverAudio: "[data-hover-button]",
      },
    });

    this.audio = new Audio("audio/hover-audio.wav");

    this.audio.volume = 0.125;

    this.init();
  }

  play() {
    this.audio.play();
  }

  stop() {
    this.audio.pause();
    this.audio.currentTime = 0;
  }

  init() {
    mapEach(this.elements.hoverAudio, (el) => {
      el.addEventListener("mouseover", (e) => {
        // check if target is button or link
        if (e.target.tagName === "BUTTON" || e.target.tagName === "A") {
          this.stop();
          this.play();
        }
      });
      el.addEventListener("mouseout", () => {
        this.stop();
      });
    });
  }
}
