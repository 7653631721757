import Component from "../classes/Component";
import gsap from "gsap";
import { DefaultLoadingManager } from "three";
import { optimizeCloudinaryUrl, getVideoPoster } from "../utils/cloudinary";
import { mapEach } from "../utils/dom";
import { BREAKPOINT_TABLET } from "../utils/breakpoints";

export default class Preloader extends Component {
  constructor() {
    super({
      element: ".preloader",
      elements: {
        title: ".preloader__text",
        numberText: ".preloader__number span",
        line: ".preloader__line",
        desc: ".preloader__desc",
        button: ".preloader__button",
      },
    });

    this.images = [...document.querySelectorAll("[data-src]")];
    this.videos = [...document.querySelectorAll("[data-video-src]")];
    this.body = document.querySelector("body");
    this.body.classList.add("no-scroll");

    this.length = 0;
    this.imagesLength = 0;
    this.loaded = false;

    // this.createTransition();
    this.createLoader();
  }

  // createTransition() {
  //   this.transition.show();
  // }

  createLoader() {
    DefaultLoadingManager.onProgress = (url, itemsLoaded, itemsTotal) => {
      this.itemsLoaded = itemsLoaded;
      this.itemsTotal = itemsTotal;

      this.onAssetLoaded();
    };

    mapEach(this.images, (image) => {
      const media = new window.Image();
      const dataSrc = image.getAttribute("data-src");
      const src = optimizeCloudinaryUrl(dataSrc);
      media.crossOrigin = "anonymous";
      media.src = src;

      media.onload = (_) => {
        image.setAttribute("src", src);
        image.removeAttribute("data-src");

        this.imagesLength += 1;
        this.onAssetLoaded();
      };

      media.onerror = () => {
        this.imagesLength += 1;
        this.onAssetLoaded();
      };
    });

    mapEach(this.videos, (video) => {
      const dataSrc = video.getAttribute("data-video-src");
      const src = optimizeCloudinaryUrl(dataSrc);
      const poster = getVideoPoster(dataSrc);

      video.setAttribute("poster", poster);
      video.setAttribute("src", src);
      video.removeAttribute("data-video-src");
    });
  }

  onAssetLoaded() {
    this.length += 1;

    if (!this.itemsLoaded) return;

    let percent = this.length / (this.images.length + this.itemsTotal);

    const roundedPercentage = Math.round(percent * 100);

    let numberText = 100;

    if (roundedPercentage <= 99) {
      numberText = `0${roundedPercentage}`;
    }

    if (roundedPercentage < 10) {
      numberText = `00${roundedPercentage}`;
    }

    this.elements.numberText.innerHTML = numberText;

    this.elements.line.style.transform = `scaleX(${percent})`;

    if (percent === 1) {
      this.onLoaded();
      this.loaded = true;
    }
  }

  onLoaded() {
    if (this.loaded) return;

    const { desc, button } = this.elements;

    desc.classList.add("active");
    button.classList.add("active");

    button.addEventListener("click", () => {
      this.transitionPreloader();
    });
  }

  transitionPreloader() {
    this.emit("completed");

    this.body.classList.remove("no-scroll");

    const delay = window.innerWidth > BREAKPOINT_TABLET ? 1 : 0;

    setTimeout(() => {
      const tl = gsap.timeline({
        onComplete: () => {
          this.destroy();
        },
      });

      gsap.set(".home__main__image__inner", {
        display: "block",
      });

      tl.to(this.element, {
        duration: 0.5,
        autoAlpha: 0,
        ease: "power3.out",
      });
    }, delay * 1000);
  }

  destroy() {
    this.element.parentNode.removeChild(this.element);
  }
}
