import Component from "../classes/Component";
import { getOffset, mapEach } from "../utils/dom";
import { random } from "../utils/math";

export default class RandomCards extends Component {
  constructor() {
    super({
      element: "[data-bounds]",
      elements: {
        cards: "[data-random-card]",
      },
    });

    this.positionCards();
  }

  positionCards() {
    // position cards randomly within the bounds of the element without touching
    const { element, elements } = this;
    const bounds = getOffset(element);

    mapEach(elements.cards, (card) => {
      const dragEl = card.querySelector("[data-drag]");
      const cardBounds = getOffset(dragEl);

      let x = random(bounds.left, bounds.width - cardBounds.width);
      let y = random(bounds.top, bounds.height - cardBounds.height);

      card.style.left = `${x}px`;
      card.style.top = `${y}px`;
    });
  }
}
