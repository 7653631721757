const Prismic = require("@prismicio/client");
import AutoBind from "auto-bind";
import each from "lodash/each";
import Detection from "./classes/Detection";
import { mapEach } from "./utils/dom";
import { BREAKPOINT_TABLET } from "./utils/breakpoints";

// import Case from "./pages/Case";
import Home from "./pages/Home";
import About from "./pages/About";
import Work from "./pages/Work";
import G256 from "./pages/256";

import Canvas from "./components/Canvas";
import Year from "./components/Year";
import Eye from "./components/Eye";
import Spotify from "./components/Spotify";
import Preloader from "./components/Preloader";
import Time from "./components/Time";
import NotFound from "./components/NotFound";
import RandomCards from "./components/RandomCards";
import SmokeAudio from "./components/SmokeAudio";
import HoverAudio from "./components/HoverAudio";

import { Cursor } from "./animations/Cursor";

class App {
  constructor() {
    AutoBind(this);

    this.url = window.location.pathname;

    this.mouse = {
      x: window.innerWidth / 2,
      y: window.innerHeight / 2,
    };

    this.createAudio();
    this.createTime();
    this.createPreloader();
    this.createCanvas();
    this.createSpotify();
    this.createEye();
    this.createCursor();
    this.createRandomCards();
    this.detectKeyStrokes();
    // this.createCase();
    // this.createHome();
    // if (this.url.indexOf("/case") > -1) {
    //   this.page = this.case;
    //   this.page.onResize();
    // } else {
    // }

    this.pages = {
      "/": new Home(),
      "/info": new About(),
      "/work": new Work(),
      "/256": new G256(),
    };

    if (!Object.keys(this.pages).includes(this.url)) {
      document.querySelector(".notfound").classList.add("notfound--active");
      this.page = null;
    } else {
      this.page = this.pages[this.url];
      this.page.show(this.url);
    }

    this.innerWidth = window.innerWidth;

    this.addEventListeners();
    this.addLinksEventsListeners();
    this.update();

    this.onResize();
    // this.fetchPrismic();
  }

  async fetchPrismic() {
    const initApi = () => {
      return Prismic.createClient(process.env.PRISMIC_ENDPOINT, {
        accessToken: process.env.PRISMIC_ACCESS_TOKEN,
      });
    };

    const handleRequest = async (api) => {
      const globals = await api.getSingle("globals");
      const works = await api.getSingle("works");
      const home = await api.getSingle("homepage");
      const about = await api.getSingle("about");
      const meta = await api.getSingle("metadata");

      // const { results: projects } = await api.query(
      //   Prismic.Predicates.at("document.type", "work")
      // );

      const projectsData = await api.getAllByType("work");

      const projects = works.data.projects.map((project) => {
        const data = projectsData.find((p) => p.id === project.project.id);

        return {
          ...data,
        };
      });

      return {
        about,
        projects,
        home,
        globals,
        meta,
        works,
      };
    };

    const api = await initApi();
    const results = await handleRequest(api);

    console.log(results);
  }

  createPreloader() {
    this.preloader = new Preloader();

    this.preloader.once("completed", this.onPreloaded.bind(this));
  }

  onPreloaded() {
    this.onResize();
    this.canvas.onPreloaded();
    this.notFound = new NotFound();

    if (window.innerWidth > BREAKPOINT_TABLET) {
      this.smokeAudio.play();
    }
  }

  createTime() {
    this.time = new Time();
    this.year = new Year();
  }

  createSpotify() {
    this.time = new Spotify();
  }

  createCursor() {
    this.cursor = new Cursor(document.querySelector(".cursor"));
    this.cursor.checkEnter();
  }

  createRandomCards() {
    this.cards = new RandomCards();
  }

  createAudio() {
    this.hoverAudio = new HoverAudio();
    this.smokeAudio = new SmokeAudio();
  }

  createEye() {
    const eyes = document.querySelectorAll(".eye");
    this.brainAnim = mapEach(eyes, (element, index) => {
      return new Eye({
        element,
        index,
      });
    });
  }

  createCanvas() {
    this.canvas = new Canvas({
      url: this.url,
    });
  }

  async onChange({ push = true, url = null }) {
    url = url.replace(window.location.origin, "");

    if (this.isFetching || this.url === url) return;

    this.isFetching = true;
    this.url = url;

    if (window.innerWidth > BREAKPOINT_TABLET) {
      const selectedVideo = document.querySelector(".smoke-video");
      this.canvas.stop();
      this.canvas.play();

      this.smokeAudio.stop();
      this.smokeAudio.play();
      this.isPlaying = false;

      selectedVideo.addEventListener("timeupdate", () => {
        if (selectedVideo.currentTime > 1 && !this.isPlaying) {
          this.isPlaying = true;

          if (this.canvas) {
            this.canvas.onChangeEnd(this.url, 0.5);
          }

          if (this.page) {
            this.page.hide();
          }

          if (!Object.keys(this.pages).includes(this.url)) {
            document
              .querySelector(".notfound")
              .classList.add("notfound--active");
            this.page = null;
          } else {
            document
              .querySelector(".notfound")
              .classList.remove("notfound--active");
            this.page = this.pages[this.url];
          }
        }
      });

      this.isShowing = false;

      if (push) {
        window.history.pushState({}, document.title, url);
      }

      selectedVideo.addEventListener("timeupdate", async () => {
        if (selectedVideo.currentTime > 1.5 && !this.isShowing) {
          this.isShowing = true;

          if (this.page) {
            await this.page.show(this.url);
            this.onResize();
          }
        }
      });
    } else {
      if (this.canvas) {
        this.canvas.onChangeEnd(url);
      }

      if (this.page) {
        this.page.hide();
      }

      if (push) {
        window.history.pushState({}, document.title, url);
      }

      if (!Object.keys(this.pages).includes(this.url)) {
        document.querySelector(".notfound").classList.add("notfound--active");
        this.page = null;
      } else {
        document
          .querySelector(".notfound")
          .classList.remove("notfound--active");
        this.page = this.pages[this.url];
      }

      // if (this.url.indexOf("/case") > -1) {
      //   this.page = this.case;
      // } else {
      //   this.page = this.pages[this.url];
      // }

      if (this.page) {
        setTimeout(() => {
          this.page.show(this.url);
          this.onResize();
        }, 500);
      }

      this.isFetching = false;
    }

    // if (this.url.indexOf("/case") > -1) {
    //   this.page = this.case;
    // } else {
    //   this.page = this.pages[this.url];
    // }

    this.isFetching = false;
  }

  /**
   * Events
   */

  onResize() {
    if (this.page) {
      this.page.onResize();
    }

    if (this.canvas) {
      this.canvas.onResize();
    }

    each(this.brainAnim, (brain) => {
      brain.onResize();
    });
  }

  onWheel(event) {
    if (this.page && this.page.onWheel) {
      this.page.onWheel(event);
    }
  }

  onPopState() {
    this.onChange({
      url: window.location.pathname,
      push: false,
    });
  }

  onTouchDown(event) {
    event.stopPropagation();

    if (!Detection.isMobile() && event.target.tagName === "A") return;

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchDown) {
      this.page.onTouchDown(event);
    }
  }

  onTouchMove(event) {
    event.stopPropagation();

    this.mouse.x = event.touches ? event.touches[0].clientX : event.clientX;
    this.mouse.y = event.touches ? event.touches[0].clientY : event.clientY;

    if (this.page && this.page.onTouchMove) {
      this.page.onTouchMove(event);
    }

    if (this.canvas && this.canvas.onTouchMove) {
      this.canvas.onTouchMove(event);
    }

    each(this.brainAnim, (brain) => {
      brain.updateTrans(event);
    });
  }

  onTouchUp(event) {
    event.stopPropagation();

    this.mouse.x = event.changedTouches
      ? event.changedTouches[0].clientX
      : event.clientX;
    this.mouse.y = event.changedTouches
      ? event.changedTouches[0].clientY
      : event.clientY;

    if (this.page && this.page.onTouchUp) {
      this.page.onTouchUp(event);
    }
  }

  /**
   * Loop
   */
  update() {
    if (this.page) {
      this.page.update();
    }

    if (this.canvas) {
      this.canvas.update();
    }

    if (this.cursor) {
      this.cursor.update();
    }

    window.requestAnimationFrame(this.update);
  }

  /**
   * Listeners
   */
  addLinksEventsListeners() {
    const links = document.querySelectorAll("a");

    each(links, (link) => {
      const isLocal = link.href.indexOf(window.location.origin) > -1;

      if (isLocal) {
        link.onclick = (event) => {
          event.preventDefault();

          this.onChange({
            url: link.href,
          });
        };
      } else if (
        link.href.indexOf("mailto") === -1 &&
        link.href.indexOf("tel") === -1
      ) {
        link.rel = "noopener";
        link.target = "_blank";
      }
    });
  }

  addEventListeners() {
    window.addEventListener(
      "resize",
      () => {
        if (this.innerWidth === window.innerWidth) return;
        this.onResize();
        this.innerWidth = window.innerWidth;
      },
      { passive: true }
    );
    window.addEventListener("popstate", this.onPopState, { passive: true });

    window.addEventListener("mousewheel", this.onWheel, { passive: true });
    window.addEventListener("wheel", this.onWheel, { passive: true });

    window.addEventListener("mousedown", this.onTouchDown, { passive: true });
    window.addEventListener("mousemove", this.onTouchMove, { passive: true });
    window.addEventListener("mouseup", this.onTouchUp, { passive: true });

    window.addEventListener("touchstart", this.onTouchDown, { passive: true });
    window.addEventListener("touchmove", this.onTouchMove, { passive: true });
    window.addEventListener("touchend", this.onTouchUp, { passive: true });
  }

  detectKeyStrokes() {
    const keyMapper = (options) => {
      const delay =
        hasProperty("keystrokeDelay", options) &&
        options.keystrokeDelay >= 300 &&
        options.keystrokeDelay;
      const keystrokeDelay = delay || 1000;
      const eventType =
        (hasProperty("eventType", options) && options.eventType) || "keydown";

      let state = {
        buffer: [],
        lastKeyTime: Date.now(),
      };

      document.addEventListener(eventType, (event) => {
        const key = event.key;
        const currentTime = Date.now();
        let buffer = [];

        if (currentTime - state.lastKeyTime > keystrokeDelay) {
          buffer = [key];
        } else {
          buffer = [...state.buffer, key];
        }
        const desiredBuffer = ["2", "5", "6"];

        if (arrayEquals(desiredBuffer, buffer)) {
          this.onChange({
            url: "/256",
          });
        }
        state = { buffer: buffer, lastKeyTime: currentTime };
      });

      function hasProperty(property, object) {
        return object && object.hasOwnProperty(property);
      }

      function arrayEquals(a, b) {
        return (
          Array.isArray(a) &&
          Array.isArray(b) &&
          a.length === b.length &&
          a.every((val, index) => val === b[index])
        );
      }
    };

    const options = {
      eventType: "keydown",
      keystrokeDelay: 1000,
    };

    keyMapper(options);
  }
}

new App();

console.log(
  "%c Made with ❤️ by Seyi — https://seyi.dev/",
  "background: #000; color: #fff;"
);
