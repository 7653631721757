uniform sampler2D uTexture;

varying vec2 vUv;

float checkBlackness(vec4 color){
    return dot(color.rgb,vec3(.2549,.902,.0588));
    // return dot(color.rgb,vec3(0.,0.,0.));
}

void main(){
    vec4 color=texture2D(uTexture,vUv);
    float frameBlackness=checkBlackness(color);
    
    gl_FragColor=vec4(color.rgb,frameBlackness);
}